import "../scss/index.scss";
import getContent from "./getContent";
import renderSection from "./renderSection";
import initScrollAnimations from "./scrollAnimations";

const contentFiles = ["section1.md", "section2.md", "section3.md"];

getContent(contentFiles)
  .then((sections) => sections.forEach(renderSection))
  .then(initScrollAnimations);
