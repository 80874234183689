import ScrollReveal from "scrollreveal";

/** Let section elements fade in into dom */
function initScrollAnimations() {
  const sectionElements = document.querySelectorAll(".section");

  sectionElements.forEach((sectionElement) => {
    const innerContainer = sectionElement.querySelector(".section__content");
    const backgroundImage = sectionElement.querySelector(
      ".section__background"
    );

    ScrollReveal().reveal(backgroundImage, {
      reset: true,
      scale: 0.5,
      duration: 500,
      beforeReveal: () => {
        backgroundImage.classList.add("section__background--revealed");
      },
      afterReset: () => {
        backgroundImage.classList.remove("section__background--revealed");
      },
    });
    ScrollReveal().reveal(innerContainer, {
      reset: true,
      delay: 200,
    });
  });
}

export default initScrollAnimations;
