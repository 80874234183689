/** Fetches specified files from the content folder
 * @param {string[]} filenames - The filenames to fetch
 * @returns {Promise<string[]>} - The fetched files as strings
 */
async function getContent(filenames) {
  const content = await Promise.all(
    filenames.map((filename) =>
      fetch(`/content/${filename}`).then((response) => response.text())
    )
  );

  return content;
}

export default getContent;
