import meta from "markdown-it-meta";
import MarkdownIt from "markdown-it/lib";

function renderSection(mdContent) {
  // format markdown content
  const markdownIt = new MarkdownIt();
  markdownIt.use(meta);

  const formatedContent = markdownIt.render(mdContent);
  const metaData = markdownIt.meta;

  // create section element
  const section = document.createElement("section");
  section.className = `section section-${metaData.layout}`;
  section.innerHTML = `
    <div class="section__background">
      <img src="${metaData.bgPath}" loading="lazy"/>
    </div>
    <div class="section__content">
     ${formatedContent}
    </div>
`;

  // append section to the DOM
  const container = document.querySelector(".sections-container");
  container.appendChild(section);
}

export default renderSection;
